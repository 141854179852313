import {ref} from "vue"
import store from '@/store'
import {toastController} from '@ionic/vue';
import {calendarOutline, checkmarkOutline, closeOutline, removeOutline} from "ionicons/icons";

const data = ref<any>(null)

export default function MatchesService() {

    const perPage = ref(25)
    const totalUsers = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('codice')
    const isSortDirDesc = ref(true)

    const userTotalcoins = ref(0)
    const userTotalmatches = ref(0)

    async function openToastOptions(data, color = 'success') {
        const toast = await toastController.create({
            color: color,
            duration: 2000,
            message: data,
        });
        await toast.present();
    }

    const fetchMatches = () => {
        store
            .dispatch('match/fetchMatches', {
                "class_id": JSON.parse(localStorage.getItem('currentClass') || '{}').id
            })
            .then(response => {
                userTotalcoins.value = response.data.data.meta.points
                userTotalmatches.value = response.data.data.meta.matches
            })
            .catch(error => {
                console.log(error.response)
                if (error.response.status != 422) {
                    openToastOptions('Impossibile caricare i matches', 'danger')
                }
            })
    }

    const fetchMatch = params => {
        store
            .dispatch('match/fetchMatch', {
                "class_id": JSON.parse(localStorage.getItem('currentClass') || '{}').id,
                "match_id": params.match_id,
            })
            .catch(error => {
                console.log(error.response)
                if (error.response.status != 422) {
                    openToastOptions('Impossibile caricare il match', 'danger')
                }
            })
    }

    const saveMatchResult = match => {
        store
            .dispatch('match/updateMatch', match)
            .catch(error => {
                console.log(error.response)
                if (error.response.status != 422) {
                    openToastOptions('Impossibile salvare il match', 'danger')
                }
            })
    }

    const getCurrentMatches = () => store.getters['match/getListMatches']
    const getCurrentMatch = () => store.getters['match/getCurrentMatch']
    const getMatchResult = () => store.getters['match/getMatchResult']

    function resolveChallengerAvatar(challenger) {
        if (challenger && challenger.avatar) {
            return {
                avatar: challenger.avatar,
                alt: challenger.avatar.printable_name,
            }
        } else if (challenger) {
            return {
                avatar: require('@/assets/avatar/avatar.svg'),
                alt: 'sfidante'
            }
        } else {
            return {
                avatar: require('@/assets/avatar/user-switaly.png'),
                alt: 'sfidante'
            }
        }
    }

    const matchResultResolver = (matchResult, matchStatus) => {
        if (matchStatus == 'done') {
            if (matchResult) {
                if (matchResult === 'winner') {
                    return {
                        text: 'Hai Vinto',
                        color: '#00cc00',
                        icon: checkmarkOutline,
                    }
                } else if (matchResult === 'loser') {
                    return {
                        text: 'Hai Perso',
                        color: '#eb445a',
                        icon: closeOutline,
                    }
                } else if (matchResult === 'draw') {
                    return {
                        text: 'Hai Pareggiato',
                        color: '#3880ff',
                        icon: removeOutline,
                    }
                }
            }
            return {
                text: 'Non hai partecipato',
                color: '#43484e',
                icon: removeOutline,
            }
        }
        return {
            text: 'In Valutazione',
            color: '#43484e',
            icon: removeOutline,
        }
    }

    const matchInRunResultResolver = matchResult => {
        if (matchResult.challenger_match) {
            if (matchResult.challenger_match.pivot.points) {
                if (matchResult.challenger_match.pivot.points < matchResult.my_points) {
                    return {
                        text: 'Hai Vinto',
                        color: '#00cc00',
                        icon: checkmarkOutline,
                        showCongratulation: true,
                    }
                } else if (matchResult.challenger_match.pivot.points > matchResult.my_points) {
                    return {
                        text: 'Hai Perso',
                        color: '#eb445a',
                        icon: closeOutline,
                        showCongratulation: false,
                    }
                } else if (matchResult.challenger_match.pivot.points == matchResult.my_points) {
                    return {
                        text: 'Hai Pareggiato',
                        color: '#3880ff',
                        icon: removeOutline,
                        showCongratulation: true,
                    }
                }
            }
            return {
                text: '',
                color: '#3880ff',
                icon: removeOutline,
                showCongratulation: true,
            }

        } else if (Math.round(matchResult.quiz.quiz.length / 100 * 80) < matchResult.my_points) {
            return {
                text: 'Hai Vinto',
                color: '#00cc00',
                icon: checkmarkOutline,
                showCongratulation: true,
            }
        } else if (Math.round(matchResult.quiz.quiz.length / 100 * 80) > matchResult.my_points) {
            return {
                text: 'Hai Perso',
                color: '#eb445a',
                icon: closeOutline,
                showCongratulation: false,
            }
        } else if (Math.round(matchResult.quiz.quiz.length / 100 * 80) == matchResult.my_points) {
            return {
                text: 'Hai Perso',
                color: '#eb445a',
                icon: closeOutline,
                showCongratulation: true,
            }
        }
    }

    return {
        data,
        getCurrentMatches,
        getCurrentMatch,
        getMatchResult,
        fetchMatches,
        fetchMatch,
        saveMatchResult,
        resolveChallengerAvatar,
        matchResultResolver,
        matchInRunResultResolver,

        perPage,
        currentPage,
        totalUsers,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        userTotalcoins,
        userTotalmatches,
    }
}
